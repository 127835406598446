import React from 'react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';
import Verifycpf from './pages/Verify';



export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" element={<Verifycpf />} exact />
            </Switch>
        </BrowserRouter>
    );
}