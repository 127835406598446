import React, { useState } from 'react';
import './styles.css';
import '../../global.css';
import Swal from 'sweetalert2';
import { checkCpfCnpj } from '../../services/client';
import env from 'react-dotenv';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import { LineScalePulseOut } from 'react-pure-loaders';

export default function Verifycpf() {
  const [cpf, setCpf] = useState('');
  const [submitting, setSubmitting] = useState(false);

  function showError() {
    Swal.fire({
      icon: 'error',
      title: 'Ops...',
      text: env.ERROR_MESSAGE
    });
  }

  async function handleVerify(e) {
    e.preventDefault();
    setSubmitting(true);

    if (cpf.length < 1) {
      showError(env.EMPTY_FIELD);
      return;
    }

    try {
      await checkCpfCnpj(cpf);
      setSubmitting(false);
      window.location.href =
        'https://cloud.ateky.net.br/index.php/apps/registration/';
    } catch (err) {
      setSubmitting(false);
      showError();
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${env.HOSTER}${env.BACKGROUND})`,
        backgroundSize: 'cover',
      }}
    >
      <div className="verify-container">
        <section className="form">
          <img
            src={`${env.HOSTER}${env.LOGO}`}
            width="280"
            height="205"
            alt="logo"
          />

          <form onSubmit={handleVerify}>
            <h1>Consulte disponibilidade</h1>

            <CpfCnpj
              placeholder="Digite seu CPF/CNPJ"
              value={cpf}
              onChange={(event, type) => {
                setCpf(event.target.value);
              }}
            />

            <button className="button" type="submit">
              {submitting ? (
                <LineScalePulseOut
                  color={'#f2f2f2'}
                  className="loading-center"
                  loading={submitting}
                />
              ) : (
                'Consultar'
              )}
            </button>
          </form>
        </section>
        <footer>
          <p className="info">
            <a href={env.COMPANY_LINK} className="entity-name">
              {env.COMPANY_NAME}
            </a>
            <span className="desc">{env.SLOGAN}</span>
            <br />
            <a href={env.LEGAL_LINK} className="legal">
              {env.LEGAL}
            </a>
            <a href={env.LEGAL_LINK} className="legal">
              {env.PRIVACY}
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}
